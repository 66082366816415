import axios from "axios";

export async function registerHistorial(entity, moduleName, tag) {
    await axios.post(`${process.env.VUE_APP_BHC_BASE_URL}/historial`, {
            entity: entity,
            module: moduleName,
            tag: tag
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then().catch(err=>console.log(err));
}